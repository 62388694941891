<template>
  <div class="container space-1">
    <div class="rounded overflow-hidden space-top-2 space-top-lg-0 px-3 px-md-8 pr-lg-0 shadow" style="background-color:#EAF4FD">
      <div class="row justify-content-lg-between align-items-lg-center no-gutters">
        <div class="col-lg-6 space-lg-1 text-center text-lg-left">
          <h2 class="mb-3">APIOps: What is it?</h2>
          <p class="mb-4">APIOps is a discipline of API development that has emerged as a result of the need for API developers and API operations professionals to work together more closely. The APIOps discipline, as it's called, builds on the traditional DevOps practice, but brings a specific focus on API development and API operations.</p>
          <a id="ga-OW02-05-03" href="https://blog.openapihub.com/en-us/apiops-what-is-it/?_gl=1*c8tsdk*_gcl_au*OTAyNDU1OTUxLjE2OTQ0MDAwNDA." class="btn btn-primary transition-3d-hover" target="_blank">Learn More about APIOps</a>
        </div>
        <div class="col-lg-6 space-top-1 ml-auto">
          <div class="w-80 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/construction.svg">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CTA'
}
</script>

<style lang="scss" scoped>
</style>
