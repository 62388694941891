<template>
  <div class="position-relative bg-img-hero" :style="{ backgroundImage: `url(${require(`@/assets/svg/components/ben-about-hero.svg`)})`}">
    <div class="container space-top-3 space-bottom-2 position-relative">
      <div class="row justify-content-lg-between align-items-center">
        <div class="col-md-7">
          <div class="mb-5 mb-md-0">
            <h1 class="mb-4">Serverless API Integration & CI/CD Pipeline</h1>
            <p class="mb-4">FabriXAPI integrates with modern Serverless API architecture and Continuous Integration (CI) and Continuous Delivery (CD) Pipeline to improve API development efficiency.</p>
            <a class="btn btn-primary transition-3d-hover my-3" href="https://provider-portal.fabrixapi.com/" target="_blank">Start for Free <i class="far fa-arrow-right ml-1" /></a>
          </div>
        </div>

        <div class="col-md-5">
          <img class="img-fluid" src="@/assets/svg/illustrations/apiops-cicd/cicd-hero.svg">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero'
}
</script>

<style lang="scss" scoped>
</style>
