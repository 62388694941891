<template>
  <div id="how-it-works" class="position-relative overflow-hidden">
    <div class="container space-2">
      <div class="w-md-85 w-lg-65 text-center mx-md-auto mb-5">
        <span class="d-block small font-weight-bold text-cap mb-2">HOW IT WORKS</span>
        <h2>Integrate with Serverless Framework & CI/CD Pipeline</h2>
        <p>FabriXAPI is able to integrate with AWS Lambda & Git CI/CD Pipeline for Rapid API Creation.</p>
      </div>
      <div class="row align-items-center mb-9">
        <div class="col-12 col-md-6 mb-2 mb-md-0">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-1.svg">
          </figure>
          <h3 class="mb-3">Convert AWS Lambda Functions with FabriXAPI Toolkit</h3>
          <p>FabriXAPI provides an API Toolkits for you to convert your AWS Lambda functions to API ready application and connect to FabriXAPI for API collaboration in minutes.</p>
        </div>
        <div class="col-12 col-md-6">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/apiops-cicd/cicd-feature-1.svg" alt="Convert AWS Lambda Functions with FabriXAPI Toolkit">
          </div>
        </div>
      </div>
      <div class="row align-items-center mb-9">
        <div class="col-12 col-md-6 mb-2 mb-md-0 order-md-2">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-2.svg">
          </figure>
          <h3 class="mb-3">Generate FabriXAPI Admin API Key</h3>
          <p>FabriXAPI is built with APIs as well. Generate your own admin API key from FabriXAPI so that you can manage your API Portal via API automated workflow (APIOps).</p>
        </div>
        <div class="col-12 col-md-6 order-md-1">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/apiops-cicd/cicd-feature-2.svg" alt="Generate FabriXAPI Admin API Key">
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-12 col-md-6 mb-2 mb-md-0">
          <figure class="max-w-8rem mb-3">
            <img class="img-fluid" src="@/assets/svg/illustrations/lego-3.svg">
          </figure>
          <h3 class="mb-3">Configure your CI/CD pipeline in Git repository</h3>
          <p>Integrate your Git CI/CD pipeline with OpenAPIHub via the admin API key. You are now able to synchronize your project files including API specification and markdown tutorial to FabriXAPI with ease.</p>
        </div>
        <div class="col-12 col-md-6">
          <div class="w-80 w-md-100 w-lg-75 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/apiops-cicd/cicd-feature-3.svg" alt="Configure your CI/CD pipeline in Git repository">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HowItWorks'
}
</script>

<style lang="scss" scoped>
</style>
